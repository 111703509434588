import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import TabbedPage, { Page } from '../../common/TabbedPage';
import BmsHistogramTab from './bmsHistogramTab/BmsHistogramTab';
import ConfigurationTab from './ConfigurationTab';
import LogFilesTab from './logFilesTab/LogFilesTab';
import SelectedDeviceHeader from './selectedDeviceHeader/SelectedDeviceHeader';
import ChargerOverviewTab from './chargerOverviewTab/ChargerOverviewTab';
import BatteryOverviewTab from './batteryOverviewTab/BatteryOverviewTab';
import { LoginContext } from '../../../Login';
import { AppContext } from '../../../App';
import EmptyFleetDeviceView from './EmptyFleetDeviceView';
import { BackendDevice } from '../../../model/backendDataModels';
import { COMPONENT_PADDING, WIDTH_OF_INFO_COMPONENT } from '../../../themes/theme';
import { DeviceItemType } from '../../../model/frontendDataModels';
import { useTheme } from '@mui/material';
import { useFleet } from '../../../dataHooks/fleetAndFleetgroupHooks';
import { useDevice, useStatus } from '../../../dataHooks/deviceHooks';
import GatewayConfigurationTab from '../selectedGatewayView/GatewayConfigurationTab';
import LoadingIndicator from '../../common/LoadingIndicator';

function createPages(
  selectedDevice: BackendDevice,
  selectedTabName: string,
  setSelectedTabName: (selectedTabName: string) => void
): Page[] {
  const pages = [];

  if (selectedDevice.productCategory === 'charger') {
    pages.push({
      name: 'Overview',
      body: <ChargerOverviewTab selectedDevice={selectedDevice} />,
    });
  } else if (selectedDevice.productCategory === 'bms' || selectedDevice.productCategory === 'bmu') {
    pages.push({
      name: 'Overview',
      body: <BatteryOverviewTab selectedDevice={selectedDevice} />,
    });
  }

  if (selectedDevice.productCategory === 'bms') {
    pages.push({
      name: 'Histogram',
      body: <BmsHistogramTab selectedDevice={selectedDevice} />,
    });
  }

  if (selectedDevice.productCategory === 'gateway') {
    pages.push({
      name: 'Configuration',
      body: <GatewayConfigurationTab selectedDevice={selectedDevice} />,
    });
  } else {
    pages.push({
      name: 'Configuration',
      body: <ConfigurationTab selectedDevice={selectedDevice} />,
    });

    pages.push({
      name: 'Logfiles',
      body: (
        <LogFilesTab treeDevice={selectedDevice} tabMode={true} selectedTabName={selectedTabName} setSelectedTabName={setSelectedTabName} />
      ),
    });
  }

  return pages;
}

type SelectedDeviceViewProps = {
  device: DeviceItemType;
};

function SelectedDeviceView(props: SelectedDeviceViewProps): JSX.Element | null {
  const defaultStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRight: '2px solid black',
    width: WIDTH_OF_INFO_COMPONENT * 2 + COMPONENT_PADDING * 2,
  };
  const fullscreenStyle = {
    ...defaultStyle,
    width: '100%',
  };

  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const theme = useTheme();

  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [selectedTabName, setSelectedTabName] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<string>('Overview');

  const { data: device, error: deviceError, isLoading: isLoadingDevice } = useDevice(props.device.mui, loginContext.accessToken);
  const { data: fleet, error: fleetError, isLoading: isLoadingFleet } = useFleet(device?.fleetId, loginContext.accessToken);
  const { data: deviceStatus, error: statusError, isLoading: isLoadingStatus } = useStatus(device, loginContext.accessToken);

  if (deviceError) {
    appContext.addBackendError(deviceError);
  }

  if (fleetError) {
    appContext.addBackendError(fleetError);
  }

  if (statusError) {
    appContext.addBackendError(statusError);
  }

  if (isLoadingDevice || isLoadingFleet || isLoadingStatus) {
    return (
      <Box
        sx={{ ...(fullscreen ? fullscreenStyle : defaultStyle), justifyContent: 'center' }}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        <LoadingIndicator />
      </Box>
    );
  }

  return (
    <Box
      sx={fullscreen ? fullscreenStyle : defaultStyle}
      style={{
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      {device && (
        <SelectedDeviceHeader
          selectedDevice={device}
          onFullscreenChanged={setFullscreen}
          fullscreen={fullscreen}
          deviceInitialized={deviceStatus?.initialized ?? false}
        />
      )}
      {deviceStatus?.initialized === false && <EmptyFleetDeviceView />}
      {deviceStatus?.initialized && device && fleet && (
        <TabbedPage
          initialized={deviceStatus?.initialized}
          tabAlignment='right'
          pages={createPages(device, selectedTabName, setSelectedTabName)}
          disableScroll={true}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      )}
    </Box>
  );
}

export default React.memo(SelectedDeviceView);
